  .career {
    background-image: linear-gradient(to bottom, rgba(38, 17, 177, 0.8) 0%, rgba(1, 130, 98, 0.8) 99.95%);
    padding-top: 6rem;
    padding-bottom: 30rem;
    /* height: 180vh; */
  }

  @media screen and (max-width: 820px) {
    .career {
      padding-top: 10rem;
    }
  }
  /* @media screen and (max-width: 480px) {
    .career {

    }
  } */

  .career h1 {
    font-size: 3rem;
    letter-spacing: -2%;
    color: white;
  }

  @media screen and (max-width: 480px) {
    .career h1 {
      font-size: 2.25rem;
      text-align: center;
    }
  }

  @media screen and (max-width: 820px) {
    .career h1 {
      text-align: center;
    }
  }

  .career_title_mobile h1 {
    font-size: 36px;
    font-weight: 400;
    line-height: 44px;
    text-align: center;

  }
  
  .txt-6{
      background: linear-gradient(103.17deg, #009E77 0%, #5C47DF 99.95%);
      background-clip: text;
      -webkit-text-fill-color: transparent;
  }
  
  .career-p {
    font-family: 'Inter';
    font-weight: 500;
    font-size: 1.5rem;
    line-height: 120%;
    letter-spacing: -2%;
    color: white;
    padding-top: 2.5rem;
    padding-bottom: 2rem;
  }

  @media screen and (max-width: 480px) {
    .career-p {
      text-align: center;
    }
  }
  
  @media screen and (max-width: 820px) {
    .career-p {
      text-align: center;
    }
  }

  
  .email {
    color: white;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 1.5rem;
    line-height: 120%;
    letter-spacing: -2%;
    padding-top: 3%;
    padding-bottom: 1.625rem;
  }

  @media screen and (max-width: 480px) {
    .email {
      text-align: center;
    }
  }
  @media screen and (max-width: 820px) {
    .email {
      text-align: center;
    }
  }
  
  .email a {
    color: white;
    text-decoration: none;
  }

  .email a:hover {
    color: white;
    font-style: italic;
    background: linear-gradient(102.32deg, #A1EBD9 1.19%, #CBC2FF 99.95%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    /* font-size: 1.54rem; */
  }

  .form_apply {
    position: relative;
  }

  @media screen and (max-width: 480px) {
    .form_apply {
      justify-content: center;
      display: flex;
    }
  }
  @media screen and (max-width: 820px) {
    .form_apply {
      justify-content: center;
      display: flex;
    }
  }
  
  .form_apply button {
    line-height: 1.188rem;
    font-weight: 450;
    letter-spacing: 2%;
    color: #312F2F;
    font-size: 1.125rem;
    background-color: #00CC99;
    width: 10.375rem;
    height: 2.188rem;
    border-radius: 0.5rem;
    border: none;
    padding: 0.5rem 1rem;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  }
  
  .form_apply button:hover {
    color: #312F2F;
    background-color: #3CDEC3;
  }
      
  .form_apply button:focus {
    color: #312F2F;
    background: #3CDEC3;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px #AFECDA;
  }
  
  