  .feature {
    background-image: linear-gradient(to right, rgba(38, 17, 177, 0.8), rgba(1, 130, 98, 0.8));
    padding-bottom: 4rem;
    padding-top: 6rem;
  }

  .feature-cont h1 {
    font-style: normal;
    font-weight: 450;
    font-size: 3rem;
    line-height: 4.188rem;
    letter-spacing: -2%;
    padding-bottom: 4rem;
    padding-top: 2rem;
    color: white;
  }

  @media screen and (max-width: 480px) {
    .feature-cont h1 {
        text-align: center;
        font-weight: 400;
        font-size: 2.25rem;
    } 
  }
  
  @media screen and (max-width: 820px) {
    .feature-cont h1 {
        text-align: center;
    } 
  }

  .features_data {
    padding-bottom: 3rem;
    align-items: center;
  }

  @media screen and (max-width: 480px) {
    .features_data {
        padding-bottom: 5rem;
        text-align: center;
      }
  }
  @media screen and (max-width: 820px) {
    .features_data {
        padding-bottom: 4rem;
        text-align: center;
      }
  }

  .title {
    font-style: normal;
    font-weight: 450;
    font-size: 2rem;
    line-height: 120%;
    letter-spacing: -2%;
    background: linear-gradient(102.32deg, #A1EBD9 1.19%, #CBC2FF 99.95%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    padding-bottom: 0.5rem;
  }

  .title_mobile {
    font-style: normal;
    font-weight: 450;
    font-size: 24px;
    line-height: 32px;
    background: linear-gradient(102.32deg, #A1EBD9 1.19%, #CBC2FF 99.95%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    padding-bottom: 0.5rem;
  }

  .para {
    color: #F9F9FF;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 0.90rem;
    line-height: 145%;
  }

  .feature_img {
    width:100%;
    padding-top: 1rem;
  }