.home {
  /* height: 87vh; */
   /* border: 2px solid green; */
   padding-bottom: 8rem;
}

.content-home {
  font-size: 1.5rem;
  font-weight: 450;
  line-height: 133%;
  padding-bottom: 2rem;
}

.content-home-tablet {
  font-size: 3rem;
  font-weight: 450;
  line-height: 133%;
  padding-bottom: 2rem;
  letter-spacing: -2%;
  padding-top: 10rem;
  /* outline: 2px solid red; */
}

.content-home-desktop {
  font-size: 2.66rem;
  font-weight: 450;
  line-height: 130%;
  letter-spacing: -2%;
  padding-top: 3rem;
}

.blur {
  padding-top: 2rem;
  background-color: rgba(255, 255, 255, 0.7);
}

.home {
  background: transparent;
  /* height: 87vh; */
  display: flex;
  align-items: center;
}

.home_content {
  font-size: 2.66rem;
  font-weight: 450;
  line-height: 130%;
  letter-spacing: -2%;
}

.home_padding {
  /* padding-bottom: 3rem; */
  /* padding-top: 3rem; */
  outline: 2px solid red;
}


.slide_txt{
  overflow: hidden;
  height: 3.625rem;
  line-height: 130%;
  margin-top: -3.1rem;
  margin-left: 13.5rem;
  /* border: 2px dashed rebeccapurple; */
}

.slide_txt div div {
  display: inline-block;
  margin-bottom: 1.55rem;
  /* border: 2px dashed darkcyan; */

}

.sliding-text{
  /* font-size: 1.6rem; */
  margin-top: -2rem;
  animation: slide-animation 5s ease infinite;
  animation-delay: 1s;
}

@keyframes slide-animation {
  0% {margin-top: -2rem;}
  25% {margin-top: -4rem;}
  50% {margin-top: -6rem;}
  75% {margin-top: -8rem;}
  75.01% {margin-top: 0;}
  100% {margin-top: -2rem;}
}

.sliding-text-desktop{
  /* font-size: 1.6rem; */
  margin-top: -3.35rem;
  animation: slide-animation-desktop 5s ease infinite;
  animation-delay: 1s;
}

@keyframes slide-animation-desktop {
  0% {margin-top: -3.35rem;}
  25% {margin-top: -6.8rem;}
  50% {margin-top: -10.2rem;}
  75% {margin-top: -13.7rem;}
  75.01% {margin-top: 0;}
  100% {margin-top: -3.35rem;}
}

.sliding-text-tablet{
  margin-top: -3.75rem;
  animation: slide-animation-tablet 5s ease infinite;
  animation-delay: 1s;
}

@keyframes slide-animation-tablet {
  0% {margin-top: -3.75rem;}
  25% {margin-top: -7.75rem;}
  50% {margin-top: -11.75rem;}
  75% {margin-top: -15.7rem;}
  75.01% {margin-top: 0;}
  100% {margin-top: -3.75rem;}
}

.slide_txt div:nth-child(1){
  margin-top: -5.313rem; /* start with the second item */
  animation: animate 5s ease infinite;
  animation-delay: 1s;
}

@keyframes animate {
  0% {margin-top: -5.313rem;}
  25% {margin-top: -10.375rem;}
  50% {margin-top: -15.625rem;}
  75% {margin-top: -20.625rem;}
  75.01% {margin-top: 0;}
  100% {margin-top: -5.313rem;}
}

.txt-1 {
  background: linear-gradient(103.17deg, #009E77 0%, #5C47DF 99.95%);
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.txt-2 {
  background: linear-gradient(103.17deg, #009E77 0%, #E75741 99.95%);
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.txt-3 {
  background: linear-gradient(103.17deg, #00CC99 0%, #FF715B 0.01%, #745EFE 99.95%);
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.txt-4 {
  background: linear-gradient(103.17deg, #FF715B 0%, #3F3F57 99.95%);
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.home-h {
  font-family: 'Inter';
  font-weight: 500;
  font-size: 0.9rem;
  line-height: 1rem;
  padding-bottom: 1rem;
  color: #3F3F57;
}

.form-email {
  font-family: 'Inter';
  font-weight: 500;
  font-size: 1rem;
  line-height: 145%;
  color: #86869A;
  /* border:2px solid red; */
  padding-bottom: 1rem;
}

.form-email i {
  position: absolute;
  padding-left: 0.938rem;
  padding-top: 0.8rem;
  color: #86869A;
}

.form-email input {
  position: static;
  width: 100%;
  height: 2.75rem;
  border-radius: 0.5rem;
  background: #F9F9FF;
  border: 0.063rem solid #B5B5B5;
  padding-left: 2.25rem;
  font-size: 0.9rem;
}

.form-email input:focus {
  border: 0.063rem solid #00CC99;
  box-shadow: 0 0.063rem 0.125rem rgba(16, 24, 40, 0.05), 0 0 0 0.25rem #AFECDA;
  background: #F9F9FF;
}

.form-subscribe {
  position: relative;
  justify-content: center;
  display: flex;
}

.form-subscribe button {
  width: 8.438rem;
  height: 2.75rem;
  border-radius: 0.5rem;
  border: none;
  padding: 0.5rem 1rem;
  background: linear-gradient(103.17deg, #00CC99 0%, #745EFE 99.95%);
}

.form-subscribe button:hover {
  background: linear-gradient(103.17deg, #009E77 0%, #5C47DF 99.95%);
}
    
.form-subscribe button:focus {
  border: 0.063rem solid #00CC99;
  box-shadow: 0 0 0 5px rgb(175, 236, 209);
}

/* box-shadow: 0 0 0 5px rgba(0, 0, 0, .25); */
