/* base styles */
* {
  position: relative;
  font-family: 'Fivo Sans Modern';
  color: black;
  scroll-behavior: smooth;
  box-sizing: border-box;
}

body {
  /* border: 2px solid red; */
  /* overflow: hidden; */
}


.row {
  width: 100%;
  margin: 0 !important;
}



.o-r{
  outline: 2px solid red;
}

.o-b {
  outline: 2px dotted blue;
}

.scroll {
  /* overflow-y: scroll; */
  scroll-behavior: smooth;
}