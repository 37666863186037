.career-form-heading {
  font-size: 3rem;
  font-weight: 450;
  color: #02021A;
  padding-bottom: 24px;
  line-height: 130%;
  letter-spacing: -2%;
}

@media screen and (max-width: 480px) {
  .career-form-heading {
    font-size: 2.25rem;
    justify-content: center;
  }
}
@media screen and (max-width: 820px) {
  .career-form-heading {
    font-size: 3rem;
    justify-content: center;
  }
}

.career-form-content {
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 120%;
  color: #86869A;
  font-family: 'Inter';
  padding-bottom: 4rem;
}

@media screen and (max-width: 480px) {
  .career-form-content {
    justify-content: center;
    text-align: center;
  }
}
@media screen and (max-width: 820px) {
  .career-form-content {
    justify-content: center;
    text-align: center;
  }
}

.card-content {
  padding-bottom: 4rem;
}

.career-card {
  background: #F9F9FF;
  border-radius: 2rem;
  height: 16.25rem;
  width: 12rem;
  margin: auto;
}


.image-card img {
  width: 90px;
  border-radius: 50%;
  margin-left: calc(50% - 50px);
  padding-top: 1rem;
  padding-bottom: 2rem;
  z-index: 1;
}

.hover-icon{
  position: absolute;
  top: 70px;
  left: 100px;
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  background: linear-gradient(102.32deg, #3CDEC3 1.19%, #A89AFF 99.95%);
}

.card-name {
  font-size: 1rem;
  font-family: 'Inter';
  font-weight: 600;
  padding-left: 1.5rem;
  color: #3F3F57;
}

.card-position {
font-size: 1rem;
font-family: 'Inter';
font-weight: 500;
padding-left: 1.5rem;
background: linear-gradient(103.17deg, #00CC99 0%, #745EFE 99.95%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
}

.card-email {
font-size: .75rem;
font-family: 'Inter';
font-weight: 500;
margin: auto;
color: #B5B5B5;
/* text-align: center; */
overflow-wrap: break-word;
padding-left: 1.5rem;

}

.career-form-container {
  justify-content: center;
  display: flex;
}

.career-form {
  background: #F9F9FF;
  width: 80%;
  border-radius: 32px;
  padding: 1rem;
}

.career-form-image {
  text-align: center;
  padding-bottom: 2rem;
}

.career-form-image img {
  border-radius: 50%;
}

.career-form-title {
  font-family: 'Inter';
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #3F3F57;
}

 /* BUTTON */

 .career-form-btn-row {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.career-form-btn button{
  height: 60px;
  width: 100%;
  background: #009E77;
  border-radius: 8px;
  border: 1px solid #009E77;
}

.career-form-btn:hover button {
background: #0C8265;
border: 1px solid #0C8265;
}

.career-form-btn button:focus {
  background-color: #3CDEC3;
  box-shadow: 0 0 10px #AFECDA;
}

.form-career {
  background: #FFFFFF;
  padding-top: 4rem;
  padding-right: 8rem;
  padding-bottom: 3rem;
  padding-left: 8rem;
}

.row1 {
    font-size: 4rem;
    color: #02021A;
    font-weight: 450;
    line-height: 4.125rem;
    letter-spacing: -2%;
  }
  
  .row2 {
    font-size: 2rem;
    color: #86869A;
    font-weight: 450;
    line-height: 120%;
    letter-spacing: -2%;
    padding-top: 3.5rem;
  }

  .form-border {
    background: #F9F9FF;
    border-radius: 2rem;
    height: 51.563rem;
  }
    
    .image-profile img {
      border-radius: 50%;
      width: 6.688rem;
      z-index: 1;
      margin-left: 35%;
    }

    .form-details {
      padding-left: 3rem;
      padding-right: 3rem;
    }

    .form-details div {
      color: #3F3F57;
      font-size: 0.875rem;
      font-family: 'Inter';
      font-style: normal;
      font-weight: 500;
      line-height: 1rem;
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
      margin-left: auto;
      margin-right: auto;
    }

    .form-details input {
        width: 20rem;
        height: 2.75rem;
        color: #86869A;
        font-size: 1rem;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 500;
        line-height: 145%;
        padding-top: 0.625rem;
        padding-bottom: 0.625rem;
        padding-left: 1.125rem;
        margin-left: 0.75rem;
        background: #F9F9FF;
    }

    .form-details input:focus {
      border: 0.063rem solid #00CC99;
      box-shadow: 0 0.063rem 0.125rem rgba(16, 24, 40, 0.05), 0 0 0 0.25rem #AFECDA;
      background: #F9F9FF;
    }

    .form-details textarea {
        width: 20rem;
        color: #86869A;
        font-size: 1rem;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 500;
        line-height: 145%;
        padding-top: 0.625rem;
        padding-left: 1.125rem;
        margin-left: 0.75rem;
        background: #F9F9FF;
    }

    .form-details textarea:focus {
      border: 0.063rem solid #00CC99;
      box-shadow: 0 0.063rem 0.125rem rgba(16, 24, 40, 0.05), 0 0 0 0.25rem #AFECDA;
      background: #F9F9FF;
    }

    input[type=file]::file-selector-button {
      border: 1px solid #B5B5B5
      ;
      padding: .2em .4em;
      border-radius: .2em;
      background-color: #F9F9FF;
      transition: 1s;
    }
    
    .career-form-title input {
      background: #F9F9FF;
      border: 1px solid #B5B5B5;
      border-radius: 8px;;

      font-family: 'Inter';
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 145%;
      color: #86869A;
    }
